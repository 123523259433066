

<template>
  <div class="main-conent main-conent-minheight" v-loading="loading">
    <el-card class="SearchBox">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="指定活动">
          <el-select v-model="searchForm.activeId" placeholder="请选择活动">
            <el-option v-for="activ in activeDrops" :key="activ.value" :value="activ.value" :label="activ.label">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="指定门店">
          <el-select v-model="searchForm.shopId" placeholder="请选择">
            <el-option v-for="item in shopDrops" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="核销状态">
          <el-select v-model="searchForm.state" placeholder="请选择核销状态">
            <el-option v-for="item in checkStateDrops" :key="item.value" :value="item.value" :label="item.label">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户昵称">
          <el-input placeholder="用户昵称" v-model="searchForm.appUserName" clearable />
        </el-form-item>
        <el-form-item label="核销店员">
          <el-input placeholder="核销人员账号/昵称" v-model="searchForm.storeName" clearable />
        </el-form-item>
        <el-form-item label="核销编码">
          <el-input placeholder="核销编码" v-model="searchForm.recordNo" clearable />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="dataBinding">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item v-for="item in props.row.items" :key="item.productId" :label="item.productName">
                <el-input v-model="item.checkCount" disabled></el-input>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="" width="70">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="right">
              <h3>核销酒水</h3>
              <p v-for="item in scope.row.items" :key="item.productId">
                {{item.productName}}：{{item.checkCount}}
              </p>
              <div slot="reference">
                <el-avatar size="large" :src="scope.row.appUserAvatar"></el-avatar>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="用户昵称" prop="appUserName" min-width="120">
        </el-table-column>
        <el-table-column label="酒水总数" prop="">
          <template slot-scope="scope">
            <span>
              {{talCheckCount(scope.row.items) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="state">
          <template slot-scope="scope">
            <el-tag :type="scope.row.state===1?'success':'info'" effect="dark">
              {{scope.row.stateName  }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="是否后台核销" prop="state">
          <template slot-scope="scope">
            <el-tag v-show="scope.row.isSysCheck" type="info" effect="dark">
              后台核销
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="店名" prop="storeName">
        </el-table-column>
        <el-table-column label="店员账号" prop="storeUserAccount">
        </el-table-column>
        <el-table-column label="店员昵称" prop="storeUserName">
        </el-table-column>
        <el-table-column label="核销编码" prop="recordNo">
        </el-table-column>
        <el-table-column label="记录时间" prop="createTimeStr">
        </el-table-column>
        <el-table-column label="核销时间" prop="checkTimeStr">
        </el-table-column>
      </el-table>
      <div class="margin-t16 text-right">
        <el-pagination background layout="prev, pager,next" :total="totalCount" :current-page="searchForm.pageIndex"
          :page-size="searchForm.pageSize" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </el-card>

  </div>
</template>
<script>
import { getCheckList } from '@/service/statistics.js';
import { dropShop } from '@/service/shop.js';
import { activities, scenes } from '@/service/dropdown.js';

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      totalCount: 0,
      searchForm: {
        activeId: 0,
        pageIndex: 1,
        pageSize: 30,
        shopId: 0,
        recordNo: '',
        storeName: '',
        appUserName: '',
        state: -1,
      },
      shopDrops: [
        {
          label: '--全部--',
          value: 0,
        }
      ],
      checkStateDrops: [
        { label: '--全部--', value: -1 },
        { label: '待核销', value: 0 },
        { label: '已核销', value: 1 },
      ]
    }
  },
  computed: {
    talCheckCount(list) {
      return function (list) {
        let count = 0;
        list.forEach(elem => {
          count += elem.checkCount;
        })
        return count;
      }
    }
  },
  async created() {
    await this.getActivitiesDropdown();
    await this.DropStores();
    await this.dataBinding();
  },
  methods: {
    //数据加载
    async dataBinding() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        getCheckList(this.searchForm).then(res => {
          this.totalCount = res.data.totalCount;
          this.tableData = res.data.data;
          this.loading = false;
          resolve();
        }).catch(err => {
          this.loading = false;
          reject();
        });
      })
    },
    //获取活动下拉数据
    async getActivitiesDropdown() {
      return new Promise((resolve, reject) => {
        activities().then(res => {
          if (res.code == 0) {
            this.activeDrops = res.data;
            this.searchForm.activeId = res.data[0].value;
            resolve();
          }
        });
      })
    },
    //获取门店下拉框数据
    async DropStores() {
      return new Promise((resolve, reject) => {
        dropShop().then(res => {
          this.shopDrops = [{
            label: '--全部--',
            value: 0,
          }];
          if (res.code == 0) {
            res.data.forEach(element => {
              this.shopDrops.push(element);
            });
          }
          resolve();
        });
      })
    },
    //选中门店
    handleChanged(shopId) {
      this.dataBinding();
    },
    //页码切换
    handleCurrentChange(val) {
      if (this.searchForm.pageIndex != val) {
        this.searchForm.pageIndex = val;
        this.dataBinding();
      }
    }
  }
}
</script>
<style scoped>
.SearchBox {
  margin-bottom: 30px;
}
</style>